const floatingCTA = document.querySelector('.floating-cta');
const heroButtons = document.querySelector('.hero-buttons');

window.addEventListener('scroll', () => {
    if (heroButtons.getBoundingClientRect().y < -50) {
        floatingCTA.classList.remove('hidden');
    } else {
        floatingCTA.classList.add('hidden');
    }
});

// Log page actions to NewRelic
const pageActionElements = document.querySelectorAll('[data-page-action]');

pageActionElements.forEach(element => {
    const action = element.getAttribute('data-page-action');
    element.addEventListener('click', (e) => {
        console.log('adding NR page action', action);
        window.NREUM.addPageAction(action);
    });
});
